import React from 'react'
import u17 from "../image/u17.png";
import u18 from "../image/u18.png";
import u19 from "../image/u19.png";
import u22 from "../image/u22.png";
import fuZhouDaXue from "../image/fuZhouDaXue.jpg";
import xiaMenHaiWai from "../image/xiaMenHaiWai.jpg";
import renMei from "../image/renMei.png";
import '../index.css'
// 底部数据
const footerData = [
    {img:u17,url:'http://www.fjvtc.com/vtp/WebSiteAction!index.action'},
    {img:u18,url:'http://www.fjhszpx.com/'},
    {img:u19,url:'https://www.xmcu.edu.cn'},
    {img:u22,url:'http://www.scssorg.com/'},
    {img:fuZhouDaXue,url:'https://www.fzu.edu.cn/',style:{width:'33%'}},
    {img:xiaMenHaiWai,url:'https://oec.xmu.edu.cn',style:{width:'33%'}},
    {img:renMei,url:'http://meiyu.renmei.com.cn/renmei3/index.html',style:{width:'33%'}},
]
class Footer extends React.Component{

    render() {
        var date = new Date();
        const year = date.getFullYear(); //获取完整的年份(4位)
        return(
            <>
                <div style={{background:'#F2F2F2',height:2,width:'100%'}}></div>
                <div style={{width:'1200px', margin:"0 auto", backgroundColor:'#fff', overflow:"hidden", padding:'1%'}}>
                    <div>
                        {
                            footerData.map(item=>{
                                return(
                                    <a href={item.url} target='_blank'>
                                        <img src={item.img} className='img-button' style={item.style?item.style:{}}/>
                                    </a>
                                )
                            })
                        }
                    </div>
                </div>
                <div style={{background:'#54c1f1', padding:'0 0'}}>
                    <div style={{width:'1200px', margin:"0 auto", backgroundColor:'#54c1f1', overflow:"hidden", padding:'1%'}}>
                        <div style={{textAlign:"center", color:'#fff'}}>
                            Copyright © {year} 智越科技（深圳）有限公司
                            <a href='https://beian.miit.gov.cn/' target='_blank' style={{marginLeft:'1%', marginRight:'1%'}}>粤ICP备20028287号-1</a>
                            公司地址：深圳市南山区科苑路8号讯美科技广场3号楼1017
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default Footer;
