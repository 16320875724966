import React from "react";

import u3 from '../image/u3.png'
import u4 from '../image/u4.png'
import u5 from '../image/u5.png'
import rm1 from '../image/u46.png'
import rm2 from '../image/rm2.png'
import rm3 from '../image/rm3.png'
import rm4 from '../image/rm4.png'
import zp1 from '../image/zp1.png'
import zp2 from '../image/zp2.png'
import zp3 from '../image/zp3.png'
import zp4 from '../image/zp4.png'
import zp5 from '../image/zp5.png'
import xmCity1 from '../image/xmCity1.png'
import xmCity6 from '../image/s30.png'
import xmCity2 from '../image/xmCity2.png'
import xmCity3 from '../image/xmCity3.png'
import xmCity4 from '../image/xmCity4.png'
import xmCity5 from '../image/xmCity5.png'
class SuccessfulCasesFrom extends React.Component {
    render() {
        return (
            <>
                {/*人美*/}
                <div style={{backgroundColor:'#FFFFFF', padding:10, fontSize:'28px', color:'#02A7F0', fontWeight:'650'}}>
                    人民美术出版社-“人美APP”
                    <div className='successText'>
                        <label>“人美APP”社秉持着人民美术出版“中正大雅 朴真至美”的人美精神，响应国家全面加强和改进美育工作的号召，精心打造的美育门户。</label>
                    </div>
                    <div className='successText'>
                        <label>人民美术出版社以“出版的人美”、“教育的人美”、“美术的人美”、“数字的人美”等“四个人美”为发展战略，以内容建设为核心，着力打造美术专业出版、教育出版、连环画出版、期刊出版等四大传统板块和教育、艺术两大新兴板块，形成集出版、教育、创作、展览、交易为一体的全产业链。“人美APP”为相关业务提供支撑，用户通过APP可购买图书、在线阅读、线上学习、了解资讯等。</label>
                    </div>
                    <div className='successText'>
                        <label>“人美APP”肩负着“教育的人美”的核心职能和社会使命。围绕美育教服务，为人民美术出版社量身定制符合我国实际情况的美育门户。</label>
                    </div>
                    <div style={{width:'100%'}}>
                        <div style={{width:'10%%', margin:'0 auto'}}>
                            <img src={rm1} style={{width:'100%',objectFit:'contain'}}/>
                        </div>
                    </div>
                    <div style={{display:"flex", flexDirection:"row",width:'100%', padding:10,justifyContent:'space-between'}}>
                        <img src={rm2} style={{width:'33%', objectFit:'contain'}}/>
                        <img src={rm3} style={{width:'33%', objectFit:'contain'}}/>
                        <img src={rm4} style={{width:'33%', objectFit:'contain'}}/>
                    </div>
                </div>
                {/*人人急救*/}
                <div style={{backgroundColor:'#FFFFFF', padding:10, fontSize:'28px', color:'#FF3131', fontWeight:'650',marginTop:10}}>
                    福建省红十字现场救护员培训
                    <div className='successText'>
                        <label>我司承接福建省红十字现场救护培训平台—人人急救APP开发任务，于2019年6月5日交付红会上线运行。如此快速、高效地完成开发，是我司技术的积累使然。</label>
                    </div>
                    <div className='successText'>
                        <label>“人人急救”网络平台，是全国首个可同时在电脑PC端、手机微信端和手机APP进行应急救护培训的网络平台。它是福建省红十字会根据中国红十字会要求，
                            对接国际联合会认证应急救护培训，规范培训标准，提高培训质量，创新培训模式，丰富培训方式做出的改革。</label>
                        <div>““人人急救”网络平台采取互联网+培训模式，线上理论学习考试，线下实操培训考核，即能方便学员学习，又能提高培训质量。怎
                            么样，方便吧，动心了吗？按照下列步骤操作，您就可以开始应急救护网上学习了！。</div>
                    </div>
                    <div className='successText'>
                        <label>福建省红十字会应急救护培训网络平台——“人人急救”于2019年6月5日正式向社会发布以来，截至12月1日，平台注册学员达20776人，已注册学员登录89045次，停留总时长16万小时，
                            平均学员停留时长9.7小时，网站（含pc端、微信端、App端）访问量61万次，最高峰访问量11335次，总点击数超过5000万次。</label>
                    </div>
                    <div style={{width:'100%'}}>
                        <div style={{width:'1025px', margin:'0 auto'}}>
                            <img src={u3} style={{width:'1025px'}}/>
                        </div>
                        <a style={{position:'relative', right:'8%', top:'-60px', width:'152px', height:'51px', float:'right'}}  href="http://www.fjhszpx.com/fjhszpx/site/NetworkCourseAction!view.action?queryInfo.id=1023" target='_blank'></a>

                    </div>
                    <div style={{display:"flex", flexDirection:"row",width:'100%', padding:10}}>
                        <img src={u4} style={{width:'60%', marginRight:'5%', height:'300px'}}/>
                        <img src={u5} style={{width:'35%', height:'20%'}}/>
                    </div>
                </div>
                {/*媒体资源库*/}
                <div style={{backgroundColor:'#FFFFFF', padding:10, fontSize:'28px', color:'#6300BF', fontWeight:'650',marginTop:10}}>
                    厦门城市学院媒体资源分检共享系统
                    <div className='successText'>
                        <label>2020年8月20日我司中标厦门城市职业学院媒体资源分检共享系统。于2020年12月22日完成项目验收并交付用户使用。</label>
                    </div>
                    <div className='successText'>
                        <label>媒体资源分检共享系统建设是基于智慧校园建设的基础之上，是智慧校园建设的一部分。本系统的建设，解决厦门城市职业学院媒体资源存储、分检、
                            应用、分享等问题。厦门城市职业学院经历多年的教学及媒体建设，
                            积累了大量的历史媒体资源，通过本次建设，整理媒体资源，将其分门别类地存入系统中，以实现对媒体资源的统一管理、智能分检、快速使用的目的。
                            将历史媒体资源及现有系统中的媒体资源进行自动化、智能化的存储和管理，并根据不同类型的授权，对不同类别、等级的资源进行智能检索和快速应用</label>
                    </div>
                    <div className='successText'>
                        <label>
                            系统采取用前后端分离的分层架构以微服务的方式，在终端使用环境上，通过办公PC的web浏览器接入资源系统，可无缝扩展至iOS客户端、
                            Android客户端、微信端及H5端，管理员及使用者可以通过不同的终端接入媒体资源分检共享系统中，对资源进行管理和应用</label>
                    </div>
                    <div className='successText'>
                        <label>媒体资源分检共享系统投入使用后，既有效解决学院资源共享难的问题，又很好地保护媒体资源存储和应用的安全，提供了资源的利用率，活化历史媒体资源。</label>
                    </div>
                    <div style={{display:"flex",flexDirection:"column",flexWrap:"wrap",height:1600,marginTop:20,justifyContent:"space-between"}}>
                        <img src={xmCity6} className='SuccessImage'/>
                        <img src={xmCity3} className='SuccessImage'/>
                        <img src={xmCity2} className='SuccessImage'/>
                        <img src={xmCity1} className='SuccessImage'/>
                        <img src={xmCity4} className='SuccessImage'/>
                        <img src={xmCity5} className='SuccessImage'/>
                    </div>
                </div>
                {/*职训在线平台*/}
                <div style={{backgroundColor:'#FFFFFF', padding:10, fontSize:'28px', color:'#70B603', fontWeight:'650',marginTop:10}}>
                    职训在线平台
                    <div className='successText'>
                        <label>我司旗下产品“职训在线”平台，与福建省人社厅直属单位福建省职业培训中心达成战略合作，采用互联网+培训模式，开展省、中央直属在闽企业职工就业、再就业、部分职工工种、技能提升等培训工作 。 培训领域涵盖公务员培训、行政事务人员培训、中医、医学美容、教师资格认证等。</label>
                    </div>
                    <div className='successText'>
                        <label>“职训在线”平台，秉承活力职训，创造价值的理念，为职业培训提供全生命周期的教与学的服务。同时，依托各工种的强大职业讲师团、专家库，以及专业的课件开发和后期制作团队，全力打造可信赖的职业教育服务平台。</label>
                    </div>
                    <div style={{width:'100%'}}>
                        <div style={{width:'10%%', margin:'0 auto'}}>
                            <img src={zp1} style={{width:'100%',objectFit:'contain'}}/>
                        </div>
                    </div>
                    <div style={{display:"flex",flexDirection:"row",width:'100%', padding:10,justifyContent:'space-between'}}>
                        <img src={zp2} style={{width:'20%', objectFit:'contain'}}/>
                        <img src={zp3} style={{width:'20%', objectFit:'contain'}}/>
                        <img src={zp4} style={{width:'20%', objectFit:'contain'}}/>
                        <img src={zp5} style={{width:'33%', objectFit:'contain'}}/>
                    </div>
                </div>
            </>
        )
    }
}

export default SuccessfulCasesFrom;
