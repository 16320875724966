import React from 'react'
import logo from "../image/u2.png";
import {Link} from "react-router-dom";
import {Menu} from "antd";
import Footer from "./Footer";
class Header extends React.Component{
    render() {
        console.log(this.props.Form)
        return(
            <div>
                <div className='head'>
                    <div className='ZY-logo'>
                        <div className='layoutList'>
                            <div>
                                <img src={logo} className='App-logo' />
                            </div>
                            <div style={{width:'30%',color:'white'}}>
                                <span style={{paddingLeft:'2%', fontSize:'1.8em', display:"block"}}>智越科技（深圳）有限公司</span>
                                <span style={{paddingLeft:'2%', fontSize:'1.6em'}}>z-uni Technology </span>
                                <span style={{fontSize:'1.6em'}}>（shenzhen） </span>
                            </div>
                            <div style={{display:'flex', flexDirection:'row', justifyContent:"space-around",paddingTop:'4px',width:'50%',margin:"0 auto" ,color:"white"}}>
                                <div className={this.props.Form == 'IndexForm' ?'selected' :'ZY-check'} onClick={()=>this.props.Navigation('IndexForm')} style={{width:'20%',padding:'5px 0',cursor:'pointer'}}>
                                    首页
                                </div>
                                <div className={this.props.Form == 'ProductServiceFrom' ?'selected' :'ZY-check'} onClick={()=>this.props.Navigation('ProductServiceFrom')} style={{width:'30%',padding:'5px 0',cursor:'pointer'}}>
                                    产品与服务
                                </div>
                                <div className={this.props.Form == 'SuccessfulCasesFrom' ?'selected' :'ZY-check'} onClick={()=>this.props.Navigation('SuccessfulCasesFrom')} style={{width:'24%',padding:'5px 0',cursor:'pointer'}}>
                                    成功案例
                                </div>
                                {/* <a  className={this.props.Form == 'jxkh' ?'selected' :'ZY-check'}
                                    href='https://www.z-uni.com:5005/'
                                    target='_blank'
                                    style={{width:'24%',padding:'5px 0',cursor:'pointer'}}>
                                    绩效考核
                                </a> */}
                                <div className={this.props.Form == 'AboutZhiYueFrom' ?'selected' :'ZY-check'} onClick={()=>this.props.Navigation('AboutZhiYueFrom')} style={{width:'24%',padding:'5px 0',cursor:'pointer'}}>
                                    关于智越
                                </div>

                                {/* <a  className={this.props.Form == 'jxkh' ?'selected' :'ZY-check'}
                                    href='https://www.z-uni.com:5005/'
                                    target='_blank'
                                    style={{width:'24%',padding:'5px 0',cursor:'pointer'}}>
                                    隐私协议
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Header;
