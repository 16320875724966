import React from "react";

import u6 from '../image/u6.9d54c6dc.png'

class aboutZhiYueFrom extends React.Component {
    render() {
        return (
            <div style={{backgroundColor:'#FFFFFF', padding:10, fontSize:'28px', color:'#02A7F0', fontWeight:'650'}}>
                智越科技
                <div style={{fontSize:'20px', textAlign:"left", padding:'1%' ,fontWeight:'400', color:'#333333'}}>
                    <label style={{paddingLeft:'4%'}}>智越科技（深圳）有限公司，是在线教育行业技术平台和解决方案的提供商及运营商，公司扎根教育领域,坚持以技术创新为核心，
                        以客户需求为导向,利用云计算、大数据、人工智能及3D呈现等先进技术，通过全媒体、多终端的展现方式 ， 营造沉浸式、个性化的学习氛围
                        ，为客户提供高效、便捷、安全、稳定的现代教育全方位解决方案。在职业培训 、 技能认证 、 继续教育 、 红十字救护等领域深得用户青睐。
                        2019年12月，智越科技（深圳）有限公司正式成为中国智慧城市建设投资联盟成员。</label>
                    <div style={{paddingLeft:'4%'}}>公司秉持“协作、创新、奋进、超越”的企业精神，通过不懈的奋斗，力争成为中国在线教育行业的领航者。</div>
                </div>
                <div style={{backgroundColor:'rgb(242, 242, 242)'}}>
                    <img src={u6} style={{width:'1112px', marginLeft:"3%", padding:'1%'}}/>
                </div>
                <div style={{fontSize:'20px', paddingLeft:'4%', paddingTop:'1%'}}>深圳地址：深圳市南山区科苑路8号讯美科技广场3号楼1017</div>
                <div style={{fontSize:'20px', paddingLeft:'4%', paddingTop:'1%'}}>电话：+86-18948713095</div>
            </div>
        )
    }
}

export default aboutZhiYueFrom;
