import React from 'react';
import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import 'antd/dist/antd.css';
import './App.css';

import Footer from "./index/Footer";
import Header from "./index/Header";
import IndexForm from "./index/indexFrom";
import ProductServiceFrom from "./index/ProductServiceFrom";
import SuccessfulCasesFrom from "./index/SuccessfulCasesFrom";
import AboutZhiYueFrom from "./index/aboutZhiYueFrom";
class App extends React.Component{
    constructor () {
        super()
        this.state= {
            Form:'IndexForm',
        }
    }


    render() {
      const navigation =(e)=>{
          this.setState({
              Form:e
          })
      };
      let content = (
          <IndexForm/>
      )
      if (this.state.Form == 'ProductServiceFrom'){
          content = (
              <ProductServiceFrom/>
          )
      }
      if (this.state.Form == 'SuccessfulCasesFrom'){
          content = (
              <SuccessfulCasesFrom/>
          )
      }
      if (this.state.Form == 'AboutZhiYueFrom'){
          content = (
              <AboutZhiYueFrom/>
          )
      }
      console.log(this.state.Form)
    return (
        <div>
            <Header Form={this.state.Form} Navigation={(e)=>navigation(e)}/>
            {/*<div style={{display:'flex', flexDirection:'row', justifyContent:"space-around",paddingTop:'4px',width:'1210px',margin:"0 auto" ,backgroundColor:"white"}}>
                <a className={this.state.Form == 'IndexForm' ?'selected' :''} onClick={()=>navigation('IndexForm')} style={{width:'24%', fontSize:'23px', textAlign:'center'}}>
                    首页
                </a>
                <a className={this.state.Form == 'ProductServiceFrom' ?'selected' :''} onClick={()=>navigation('ProductServiceFrom')} style={{width:'24%', fontSize:'23px', textAlign:'center'}}>
                    产品与服务
                </a>
                <a className={this.state.Form == 'SuccessfulCasesFrom' ?'selected' :''} onClick={()=>navigation('SuccessfulCasesFrom')} style={{width:'24%', fontSize:'23px', textAlign:'center'}}>
                    成功案例
                </a>
                <a className={this.state.Form == 'AboutZhiYueFrom' ?'selected' :''} onClick={()=>navigation('AboutZhiYueFrom')} style={{width:'24%', fontSize:'23px', textAlign:'center'}}>
                    zpstyle={{width:'25%', objectFit:'contain'}}
                </a>
            </div>*/}
            <div>
                <div style={{ padding: 0, minHeight: 400,width:'1200px', margin:"1% auto", backgroundColor:'rgba(242, 242, 242, 1)', overflow:"hidden"}}>
                    {content}
                </div>

            </div>
             <Footer/>
        </div>
    );

  }

}

export default App;
