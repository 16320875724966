
import u32 from '../../image/u32.jpeg'
import u33 from '../../image/u33.png'
import u27 from '../../image/u27.png'
import u23 from '../../image/u23.png'
import u28 from '../../image/u28.png'
import u30 from '../../image/u30.png'
import u40 from '../../image/u40.jpg'
import u42 from '../../image/u42.jpg'
import u47 from '../../image/u47.png'
import u48 from '../../image/u48.png'
import u51 from '../../image/u51.png'
import u52 from '../../image/u52.png'
import u65 from '../../image/u65.png'
import u66 from '../../image/u66.png'
import u70 from '../../image/u70.png'
import u71 from '../../image/u71.png'
// 基础数据 （纵向排列）
export const baseDataList = [
    {
        name:'\n' +
            '绩效考核',
        titleColor:'#65C5EE',
        content:'绩效可和系统是为满足企业日常绩效考核而研发的一套软件系统，本系统提供全方位匿名的评价功能，企业可以根据自身考核的要求指定考核计划，并通过复制或分享的方式发布考核任务，评分人无需登录，可以通过网页、微信等直接进行考核评分。适合围绕企业内部、供应商、客户等维度的综合绩效考核。系统提供可视化的数据展现、汇总、导出等功能。',
        img1:u70,
        img2:u71,
    },
    {
        name:'人人急救',
        titleColor:'#FF3131',
        content:'人人急救，是全国首个可同时在电脑PC端、微信端和手机APP进行应急救护培训的网络平台。规范培训标准，\n' +
            '                            提高培训质量，创新培训模式，丰富培训方式。人人急救采取互联网+培训模式，线上理论学习考试，线下实\n' +
            '                            操培训考核，即能方便学员学习，又能提高培训质量。通过互联网及移动互联网，就可以开始应急救护网上学习了！',
        img1:u47,
        img2:u48,
    },
    {
        name:'媒体资源库',
        titleColor:'#A165D8',
        content:'媒体资源系统是一款针对媒体资源存储、共享、应用及管理的一体化智能平台。通过人工智能与大数据等核心技术，实现资源汇聚、智能检索、加密防盗、可视统计等功能。创新的全媒体资源展现，将各种类型的媒体资源精彩呈现在用户面前，丰富了学习和阅读的内容展示方式，营造多彩的学习氛围，提高学习效果，增强阅读体验。系统提供多终端智能化的媒体资源解决方案！',
        img1:u51,
        img2:u52,
    },
    {
        name:'美育培训',
        titleColor:'#02A7F0',
        content:'全媒体美育培训系统是一款针对美育培训教育的全媒体综合平台，集视频、音频、图片、文字、动画、游戏、直播、互动、练习、答疑于一体，以轻松愉快、跟随学习、身临其境的方式进行美育教学。全媒体美育培训系统打破传统的美育教学模式，让学生更容易掌握美育学习中的核心技巧。借助Android端、iOS端、微信端、H5端及网页端接入，实现全终端学习环境。',
        img1:u65,
        img2:u66,
    },
    {
        name:'人人教育',
        titleColor:'#02A7F0',
        content:'人人教育运营是一款多终端接入的教育培训类运营平台，用户可以通过iOS移动设备、Android移动设备、\n' +
            '                             微信、电脑浏览器、H5浏览器接入的网络教育培训平台，人人教育旨在通过互联网、移动互联网的环境，\n' +
            '                             给学习者带来全天候、混合式的学习体验，帮助培训管理者提升管理效率，实现数字化、流程化、智能化\n' +
            '                             的教育管理，开启一站式、移动式的人人教育。',
        img1:u32,
        img2:u42,
    },
    {
        name:'人人培训',
        titleColor:'#00C6BC',
        content:'人人培训是基于职业培训业务要求量身定制的培训平台，从职业培训的实际需求出发，结合教、学、管理、监察、\n' +
            '                            应用及服务等方面，通过iOS移动设备、Android移动设备、微信、电脑浏览器、H5浏览器等方式接入，实现全天候、\n' +
            '                            混合式的学习方式，创建沉浸式的学习体验。利用人工智能及大数据，提升培训效果，让职业教育，学以致用，创造价值。',
        img1:u40,
        img2:u33,
    },
]
// 技术优势 数据
export const technicalDataList = [
    {
        name:'人工智能',
        content:'平台实现基于神经网络的人工智能应用，包括证件识别、证件照识别、学情分析等。',
        img:u27,
        contentBottom:'2%',// 文字下边外间距
    },
    {
        name:'大数据',
        content:'平台基于大数据架构图体现，实现培训数据存储、建模、运算、应用及展示。',
        img:u23,
        contentBottom:'2%',// 文字下边外间距
    },
    {
        name:'分布式架构',
        content:'采用分布式的云架构，实现随需计算机存储，为大规模、高并发应用提供架构支持。',
        img:u30,
        contentBottom:'2%',// 文字下边外间距
    },
    {
        name:'数据可视化',
        content:'基于数据模型和可视化技术，实现培训数据可视化。',
        img:u28,
        contentBottom:'6%',// 文字下边外间距
    },
]
