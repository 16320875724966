import React from "react";

import u21 from '../image/u21.png'
class IndexForm extends React.Component {
    render() {
        return (
            <div >
                <div >
                    <img src={u21} style={{width:'100%', paddingLeft:'20%',objectFit:'contain',}}/>
                </div>
                <div style={{padding:20}}>
                    <span style={{paddingLeft:'1%'}}></span>
                    智越科技（深圳）有限公司，是在线教育行业技术平台和解决方案的提供商及运营商，公司扎根教育领域,坚持
                    以技术创新为核心，以客户需求为导向,利用云计算、大数据、人工智能及3D呈现等先进技术，通过全媒体、多
                    终端的展现方式 ， 营造沉浸式、个性化的学习氛围，为客户提供高效、便捷、安全、稳定的现代教育全方位解
                    决方案。在职业培训 、 技能认证 、 继续教育 、 红十字救护等领域深得用户青睐。2019年12月，智越科技（深圳）
                    有限公司正式成为中国智慧城市建设投资联盟成员。
                    <div>
                        <span style={{paddingLeft:'1%'}}></span>
                        公司秉持“协作、创新、奋进、超越”的企业精神，通过不懈的奋斗，力争成为中国在线教育行业的领航者。
                    </div>
                </div>
            </div>
        )
    }
}

export default IndexForm;
