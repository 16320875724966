import React from "react";

import {baseDataList,technicalDataList} from './js/Product'
class ProductServiceFrom extends React.Component {
    render() {
        return (
            <div style={{}}>
                {
                    baseDataList.map(item=>{
                        return(
                            <div style={{backgroundColor:'#FFFFFF', display:'flex', flexDirection:'row', justifyContent:"space-around", padding:10, marginBottom:'1%'}}>
                                <div style={{width:"32%", fontSize:'28px', color:item.titleColor, fontWeight:'650', height:'30%'}}>
                                    {item.name}
                                    <div style={{fontSize:'16px', fontWeight:'400',color:'#333333'}}>
                                        <span style={{paddingLeft:'10%'}}></span>
                                        {item.content}
                                    </div>
                                </div>
                                <img src={item.img1} style={{width:"402px", height:'259px', marginRight:'1%'}}/>
                                <img src={item.img2} style={{width:"402px", height:'259px'}}/>
                            </div>
                        )
                    })
                }
              <div style={{backgroundColor:'#FFFFFF', fontSize:'28px', color:'#02A7F0', fontWeight:'650', padding:10}}>
                  技术优势
                  <div style={{display:'flex', flexDirection:'row', justifyContent:"space-around", flexWrap:"wrap"}}>
                      {
                          technicalDataList.map(item=>{
                              return (
                                  <div style={{width:"50%", fontSize:'28px', fontWeight:'650'}}>
                                      <div style={{color:'#00BFBF', paddingLeft:'1%', fontWeight:'550'}}>
                                          {item.name}
                                          <div style={{fontSize:'16px', fontWeight:'400', color:'#333333', marginBottom:item.contentBottom}}>
                                              <span style={{paddingLeft:'5%',}}></span>
                                              {item.content}
                                          </div>
                                      </div>
                                      <img src={item.img} style={{paddingLeft:'6%', width:"100%", height:'50%'}}/>
                                  </div>
                              )
                          })
                      }
                  </div>
              </div>
            </div>
        )
    }
}

export default ProductServiceFrom;
